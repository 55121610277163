<template>
  <tr>
    <td>
      <router-link
        class="text-decoration-none pointer"
        :to="toDoc(item.parent.doc)">
        <small class="text-warning">{{item.parent.doc.code}}</small>
      </router-link>

      <div v-for="child in item.children" :key="child.id">
        <router-link
          class="text-decoration-none pointer"
          :to="toDoc(child.doc)">
          <small class="text-success">{{child.doc.code}}</small>
        </router-link>
      </div>
    </td>
    <td>
      <div
        v-for="ledger in item.ledgers"
        :key="ledger.id"
        class="no-wrap">
        <small>
          <span :class="{
            'text-success': ledger.approvedAt,
            'text-warning': !ledger.approvedAt
          }">
            {{ledger.account.code}}: {{ledger.account.name}}
          </span>
          <span v-if="ledger.stockId" class="text-info">
            <fa icon="link"></fa>
          </span>
        </small>
      </div>
      <span class="no-wrap">{{item.name}}</span>
      <div v-for="stock in item.stocks" :key="stock.id" class="text-primary">
        <small>
          <em>{{ stock.inventory.code }} ({{stock.inventory.name}})</em>
        </small>
      </div>
    </td>
    <td>
      <div v-for="ledger in item.ledgers" :key="ledger.id"><br></div>

      <span v-if="item.type === 'item'">
        <span :class="{'text-success': item.qty > 0, 'text-danger': item.qty < 0}">
          {{ Number(item.qty) | comma }}
        </span>
        <span>{{ item.unit.name }}</span>
      </span>
      <span v-else>-</span>

      <div
        class="no-wrap"
        v-for="stock in item.stocks"
        :key="stock.id">
        <small>
          <fa
            icon="print"
            class="pointer"
            @click.stop="printTag(stock.parentId, 1)"
            :class="{'text-warning': stock.isPrinted}">
          </fa>
          <span :class="{'text-success': stock.approvedAt, 'text-warning': !stock.approvedAt}">
            TAG: {{stock.id}}
          </span>
          (
            <span
              :class="{
                'text-success': stock.qty > 0,
                'text-danger': stock.qty < 0
                }">
              {{stock.qty | comma}}
            </span>
          )
          <span>
            {{stock.inventory.unit.name}}
          </span>
          <span class="text-secondary">
            ({{stock.parentId}})
          </span>
        </small>
      </div>
    </td>
    <td class="text-right">
      <span v-for="ledger in item.ledgers" :key="ledger.id"><br></span>
      <span v-if="item.price > 0">{{ item.price | comma  }}</span>
      <span v-else>-</span>
      <small v-if="item.discount" class="text-primary">
        <br>{{item.discount}} ({{item.discountPrice}})
      </small>
    </td>
    <td class="text-right">
      <span v-for="ledger in item.ledgers" :key="ledger.id">
        <small :class="{'text-success': ledger.amount > 0, 'text-danger': ledger.amount < 0}">
          {{Math.abs(ledger.amount) | comma}}
        </small>
        <br>
      </span>
      {{ item.totalPrice | comma  }}
    </td>
  </tr>
</template>

<script>
import {
  LIST_ORDER,
  PRINT_STOCK_TAG
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    toDoc (doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: {docId: doc.id}
      }
    },
    printTag (id, qty) {
      this.$apollo.mutate({
        mutation: PRINT_STOCK_TAG(this.templateType),
        variables: {
          docType: this.docType,
          stockId: id,
          qty: +qty
        },
        update: (store) => {
          const cacheQuery = {
            query: LIST_ORDER(this.templateType),
            variables: {
              docType: this.docType,
              docId: this.docId
            }
          }
          const data = store.readQuery(cacheQuery)
          const orders = [...data.orders]
          const idx = orders.findIndex(order => order.id == this.item.id)
          const stIdx = orders[idx].stocks.findIndex(stock => stock.parentId === id)

          if (stIdx !== -1) {
            data.orders[idx].stocks[stIdx].isPrinted = true
            store.writeQuery({...cacheQuery, data})
          }
        }
      })
      .then(() => {
        this.$toasted.global.success("ส่งข้อมูลไปยังเครื่องพิมพ์")
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
