<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-check-array
          inline
          label="รายการสั่งซื้อ"
          :options="docs"
          select="id"
          class="col-12"
          v-model="selected">
          <template slot-scope="option">
            {{option.code}}
          </template>
        </sgv-input-check-array>
      </div>

      <div class="form-row">
        <sgv-input-date
          label="วันที่รับเอกสาร"
          v-model="approvedDate"
          class="col-12 col-md-4"
          :validations="[
            {text: 'required!', value: $v.approvedDate.$dirty && $v.approvedDate.$error}
          ]">
        </sgv-input-date>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  APPROVE_DOC, LIST_AVAILABLE_DOC
} from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      docs: [],
      approvedDate: this.$date.current().date,
      selected: []
    }
  },
  validations: {
    approvedDate: { required }
  },
  apollo: {
    docs: {
      query () {
        return LIST_AVAILABLE_DOC(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    approveData () {
      this.$v.approvedDate.$touch()
      if (this.$v.approvedDate.$invalid) return

      this.$apollo.mutate({
        mutation: APPROVE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          approvedDate: this.approvedDate,
          docs: this.selected
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("จัดส่งสินค้า")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
