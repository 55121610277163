<template>
  <sgv-table
    :items="ordersMap"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item}">
      <DetailOrderRow
        :item="item"
        @selected="selectedRow">
      </DetailOrderRow>

      <template v-if="selectedRows.includes(item.id)">
        <DetailOrderRowChild
          v-for="child in item.children"
          :key="child.id"
          :item="child"
          :docId="docId"
          :docType="docType"
          :templateType="templateType">
        </DetailOrderRowChild>
      </template>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import DetailOrderRow from './DetailOrderRow.vue'
import DetailOrderRowChild from './DetailOrderRowChild.vue'
import { round } from 'lodash'
import {
  LIST_ORDER,
  WATCH_ORDER_CREATED,
  WATCH_ORDER_UPDATED,
  WATCH_ORDER_DESTROYED
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      rKey: `Doc${this.$form.capitalize(this.docType)}DetailOrder`,
      filter: {
        limit: null,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'qty', 'unit', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      orders: [],
      selectedRows: []
    }
  },
  apollo: {
    orders: {
      query () {
        return LIST_ORDER(this.templateType)
      },
      variables () {
        return {
          docType: this.docType,
          docId: this.docId
        }
      },
      fetchPolicy: 'network-only',
      subscribeToMore: [
        {
          document () {
            return WATCH_ORDER_CREATED(this.templateType)
          },
          variables () {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newOrders = [
              subscriptionData.data.orderCreated,
              ...previous.orders
            ]
            const result = {
              ...previous,
              orders: newOrders
            }
            return result
          }
        },
        {
          document () {
            return WATCH_ORDER_UPDATED(this.templateType)
          },
          variables () {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const updatedOrder = subscriptionData.data.orderUpdated
            const idx = previous.orders.findIndex(v => v.id === updatedOrder.id)

            if (idx === -1) return previous

            const newOrders = [...previous.orders]
            newOrders[idx] = updatedOrder

            const result = {
              ...previous,
              orders: newOrders
            }
            return result
          }
        },
        {
          document () {
            return WATCH_ORDER_DESTROYED(this.templateType)
          },
          variables () {
            return {
              docType: this.docType,
              docId: this.docId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const idx = previous.orders.findIndex(v => v.id === subscriptionData.data.orderDestroyed.id)

            if (idx === -1) return previous

            const newOrders = [...previous.orders]
            newOrders.splice(idx, 1)
            const result = {
              ...previous,
              orders: newOrders
            }
            return result
          }
        }
      ]
    }
  },
  computed: {
    ordersMap () {
      return [
        ...this.serializeOrders('item', this.orders),
        ...this.serializeOrders('service', this.orders),
        ...this.serializeOrders('expense', this.orders),
        ...this.serializeOrders('discountPrice', this.orders),
        ...this.serializeOrders('vatPrice', this.orders),
        ...this.serializeOrders('netPrice', this.orders)
      ].map((v, i) => {
        v.id = i + 1
        return v
      })
    },
    headers () {
      return [
        {text: 'ใบสั่งซื้อ', value: 'id'},
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา (${this.ledgersSum})`, value: 'totalPrice'}
      ]
    },
    ledgersSum () {
      const total = this.orders
      .flatMap(v => v.ledgers)
      .reduce((sub, ledger) => sub += +ledger.amount, 0)

      return round(total, 2)
    },
  },
  methods: {
    serializeOrders (type, orders) {
      return orders
      .filter(order => order.type === type)
      .reduce((t,order) => {
        const parent = order.parent
        const obj = {
          name: parent.name,
          type: parent.type,
          qty: +order.qty,
          unit: parent.unit.name,
          price: parent.price,
          totalPrice: +order.totalPrice,
          parentDocs: [
            parent.doc.code,
            ...order.children.map(v => v.doc.code)
          ],
          children: [order],
          inventory: parent.inventory
        }
        if (t.length === 0) {
          t.push(obj)
        } else {
          let foundIndex = -1

          if (order.type === 'item') {
            foundIndex = t.findIndex(x => x.name === obj.name && x.price === obj.price && x.unit === obj.unit)
          } else {
            foundIndex = t.findIndex(x => x.name === obj.name)
          }

          if (foundIndex !== -1) {
            t[foundIndex].qty += obj.qty
            t[foundIndex].totalPrice += obj.totalPrice
            t[foundIndex].parentDocs = [...t[foundIndex].parentDocs, ...obj.parentDocs]
            t[foundIndex].children = [...t[foundIndex].children, order]
          } else {
            t.push(obj)
          }
        }
        return t
      }, [])
      .map(order => {
        return {
          name: order.name,
          type: order.type,
          qty: order.qty,
          unit: order.unit,
          price: order.price,
          totalPrice: order.totalPrice,
          parentDocs: [...new Set(order.parentDocs)].sort((a,b) => a.localeCompare(b)),
          children: order.children,
          inventory: order.inventory
        }
      })
    },
    selectedRow (id) {
      const idx = this.selectedRows.findIndex(v => v === id)
      if (idx !== -1) {
        this.selectedRows.splice(idx, 1)
      } else {
        this.selectedRows.push(id)
      }
    },
  },
  components: {
    DetailOrderRow,
    DetailOrderRowChild
  }
}
</script>

<style lang="css">
</style>
