<template>
  <tr>
    <td class="pointer" @click="emitSelected(item.id)">
      <div v-for="doc in item.parentDocs" :key="doc">
        {{doc}}
      </div>
    </td>
    <td class="pre-line">
      {{item.name}}
      <div v-if="item.inventory">
        <small class="text-primary">
          {{item.inventory.code}} ({{item.inventory.name}})
        </small>
      </div>
    </td>
    <td>
      <div v-if="['item', 'service'].includes(item.type)">
        <span :class="{'text-success': item.qty > 0, 'text-danger': item.qty < 0}">
          {{item.qty | comma}}
        </span>
        <span>{{item.unit}}</span>
      </div>
      <div v-else>
        -
      </div>
    </td>
    <td class="text-right">
      <span v-if="item.price > 0">{{item.price | comma}}</span>
      <span v-else>-</span>
      <div v-if="item.discount" class="text-primary">
        <small>{{item.discount}} ({{item.discountPrice}})</small>
      </div>
    </td>
    <td
      class="text-right"
      :class="{'text-danger': item.totalPrice < 0}">
      {{item.totalPrice | comma}}
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    emitSelected (id) {
      this.$emit('selected', id)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
